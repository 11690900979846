import React, { useEffect, useState } from "react";
import CourseHeader from "../header/index";
import { Col, Row } from "react-bootstrap";
import {
    Link,
    // useLocation,
    useParams
} from "react-router-dom";
import '../Journal/journal.css'
import Footer from "../footer";
// import InnerBanner from "../../assets/img/inner-banner.jpg";
import StudentSideBar from "../student/sidebar";

// import {
//     useDispatch
//     // , useSelector
// } from "react-redux";
// import { SpissuesgetById } from "../../Redux/SpecialIssues/specialissueSlice";
import Bannerpage from "../Banner";
// import queryString from 'query-string';
import moment from "moment";
import { Pdf } from "../imagepath";
import axios from "axios";

const ArticleHtml = () => {

    // const { spIssue } = useSelector((state) => state.spIssueData)
    // const location = useLocation();
    // const query = queryString.parse(location.search);

    // Parse the article data back to an object
    // const article = query.article ? JSON.parse(query.article) : null;

    const [article, setArticle] = useState({})

    // const dispatch = useDispatch();
    const { ArticleHtml, slug } = useParams();

    const getArchiveDetailesBySlug = () => {
        axios.get(`${process.env.REACT_APP_URL}/articleDetails/${ArticleHtml}`)
            .then((res) => {
                console.log(res?.data)
                setArticle(res?.data?.data?.[0])
            })
            .catch((err) => {
                console.log(err)
            })
    }

    console.log(article, "Parsed article");


    useEffect(() => {
        // dispatch(SpissuesgetById(slug));
        getArchiveDetailesBySlug()
    }, [])

    // const { spIssue } = useSelector((state) => state.spIssueData)
    // const location = useLocation();
    // const query = queryString.parse(location.search);

    // // Parse the article data back to an object
    // const article = query.article ? JSON.parse(query.article) : null;

    // console.log(article, "Parsed article");

    // const dispatch = useDispatch();
    // const { slug } = useParams();

    // useEffect(() => {
    //     dispatch(SpissuesgetById(slug));
    // }, [])

    return (
        <>
            <CourseHeader activeMenu={"CourseDetails"} />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            Courses
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            All Courses
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            The Complete Web Developer Course 2.0
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Bannerpage />

            <div className="container p-5">
                <Row>
                    <StudentSideBar />
                    <Col style={{ backgroundColor: "#fff" }}>
                        {/* <div className="cardb" style={{ paddingTop: 10 }}>
                            <Row>
                                <Col>
                                    <label>{article?.article_types?.[0]?.name} - {volume}</label>
                                </Col>
                                <Col>
                                    <div style={{ textAlign: 'right' }}>
                                        <Link
                                            to={article?.pdf_url}
                                            target="_blank"
                                        >
                                            <button>
                                                View PDF
                                            </button>
                                        </Link> &nbsp; <PdfDownloader pdfUrl={article?.pdf_url} fileName="document.pdf">Download PDF</PdfDownloader>
                                    </div>
                                </Col>
                            </Row>
                        </div> */}
                        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                            <h1><u>Abstract</u></h1>
                        </div>
                        <div>
                            <strong style={{ fontSize: 25 }}>{article?.article_title}</strong>
                        </div>
                        <div>
                            <label>
                                <Link
                                    to={`/${slug}/author/${article?.slug}/article`}
                                    state={"article"}
                                // target="_blank"
                                >
                                    <em>{article?.authors?.[0]?.author_name}</em>
                                </Link></label>
                        </div>
                        {article?.fulltexts?.map((item, articleIndex) => {
                            return <div key={articleIndex}>
                                {/* <h3>{item?.heading_details?.[0]?.heading}</h3> */}
                                <div dangerouslySetInnerHTML={{ __html: item?.content }}></div>
                            </div>
                        })}
                        <div>
                            <strong>Published Date: </strong> {moment(article?.published_date)?.format('DD-MMM-YYYY')};
                        </div>
                        <div >
                            <Link
                                to={article?.pdf_url}
                                target="_blank"
                            >
                                <img
                                    src={Pdf}
                                    alt="img"
                                    className="img-fluid"
                                />
                            </Link>
                        </div>
                        <Row>
                            <Col>
                                <div className="rating">
                                    <label>Share this article </label>
                                    <i className="fa-brands fa-facebook"></i>
                                    <i className="fa-brands fa-twitter"></i>
                                    <i className="fa-brands fa-linkedin"></i>
                                    <i className="fa-brands fa-instagram"></i>
                                </div>
                            </Col>
                        </Row>
                        {/* <div className="card"
                            style={{
                                borderColor: 'lightgray',
                                backgroundColor: '#f8f9fa',
                                padding: 20,
                                borderRadius: 4
                            }}>
                            <strong style={{ fontSize: 20 }}>Author Info</strong>
                            <div>
                                <label>{article?.authors?.[0]?.author_name}</label>
                            </div>
                            <label>Department of {article?.authors?.[0]?.department}, {article?.authors?.[0]?.institue_college}, {article?.authors?.[0]?.country}</label>
                            <div style={{ marginTop: 20 }}>
                                <b>Citation:</b> {article?.article_citation}
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <b>Received:</b> {moment(article?.received_date).format('DD-MMM-YYYY')}, Manuscript No. {article?.manuscript_number}; <b>Editor assigned:</b> {moment(article?.editor_assigned)?.format('DD-MMM-YYYY')}, Pre QC No. {article?.pre_qc_number} (PQ); <b>Reviewed:</b> {moment(article?.reviewed_date)?.format('DD-MMM-YYYY')}, QC No. {article?.qc_number}; <b>Revised:</b> {moment(article?.revised_date)?.format('DD-MMM-YYYY')}, Manuscript No. {article?.manuscript_number} (R); <b>Published:</b> {moment(article?.published_date)?.format('DD-MMM-YYYY')} , DOI: {article?.doi}
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <b>Copyright:</b> {article?.article_copyright}

                            </div>
                        </div> */}

                    </Col>

                </Row>
            </div>
            <Footer />
        </>
    )
}

export default ArticleHtml