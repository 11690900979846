import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import '../Journal/journal.css'
import Footer from "../footer";
import StudentSideBar from "../student/sidebar";
import CourseHeader from "../header/index";
import Bannerpage from "../Banner";
import { useDispatch, useSelector } from "react-redux";
import { ArchivegetById } from "../../Redux/Archive/archiveSlice";

const Archive = () => {

    const { archive } = useSelector((state) => state.archiveData)

    const dispatch = useDispatch();
    const { slug } = useParams();

    useEffect(() => {
        dispatch(ArchivegetById(slug));
    }, [])

    return (
        <>
            <CourseHeader activeMenu={"CourseDetails"} />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            Courses
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            All Courses
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            The Complete Web Developer Course 2.0
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Bannerpage />

            <div className="container p-2">
                <Row>
                    <StudentSideBar />
                    <Col style={{ backgroundColor: "#fff" }}>
                        <div className="cardb">
                            <h1>Archive</h1>
                            {archive?.data?.map((item, index) => {
                                return <div key={index}>
                                    <div className="cardd">
                                        <div className="card-body">
                                            {item?.year}
                                        </div>
                                    </div>
                                    <ul className="">
                                        {item?.data?.map((subItem, subIndex) => {
                                            return <Link key={subIndex} to={`/${slug}/volume-page/${window.btoa(subItem?.volume_issue_uuid)}/${window.btoa(`Volume ${subItem?.volume}, Issue ${subItem?.issue}, ${item?.year}`)}`}>
                                                <li><i className="fas fa-chevron-right" />&nbsp; Volume {subItem?.volume}, Issue {subItem?.issue}</li>
                                            </Link>
                                        })}

                                    </ul>
                                </div>
                            })}
                        </div>
                    </Col>
                </Row>
            </div>
            <Footer />
        </>
    )
}

export default Archive