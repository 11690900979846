import React, { useEffect } from 'react'
import Header from '../header'
import Footer from '../footer'
import { useParams } from 'react-router-dom'
import { 
    // JournalList, 
    JournalListPageDynamic } from "../../Redux/journal/journalslice";
import { useDispatch, useSelector } from "react-redux";

const Editorialpolicies = () => {

    const { name } = useParams();
    const dispatch = useDispatch();
    const { journalList } = useSelector((state) => state.jounalListData)

    useEffect(() => {
        // dispatch(JournalList(window.atob(name)));
        dispatch(JournalListPageDynamic(name));

    }, [name])

    // const RemoveTags = (string) => {
    //   const withoutTags = string.replace(/<\/?[^>]+(>|$)/g, "");
    //   return withoutTags;
    // }

    return (
        <>
            <div>
                <Header />
                <div className="breadcrumb-bar">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <div className="breadcrumb-list">
                                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                                        {/* <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item">Pages</li>
                                        <li className="breadcrumb-item">Support</li>
                                    </ol> */}
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <h1 className="mb-0">{journalList?.data?.page_heading}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='topspace'>
                    <div className='container '>
                        <div className='card'>
                            <div className='card-body'>
                                <h5>Overview</h5>
                                <div dangerouslySetInnerHTML={{ __html: journalList?.data?.page_content }}></div>
                                {/* <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis velit sed tellus
                                    blandit vestibulum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec urna
                                    diam, iaculis ac lacus eu, viverra faucibus augue. Etiam venenatis orci et diam fringilla
                                    pharetra. Fusce nec efficitur tortor. Nulla facilisi. Phasellus gravida libero non erat
                                    sodales, eget consequat turpis placerat. Quisque ut vulputate turpis. Nulla tempor libero
                                    neque, et gravida ex accumsan et. Sed vitae scelerisque nulla. Suspendisse nec nisl auctor,
                                    scelerisque nisi tempor, feugiat nisl. Suspendisse purus massa, dapibus a hendrerit quis,
                                    aliquam et est. Etiam posuere nisi in velit eleifend tincidunt. Fusce sit amet ultrices
                                    odio. Praesent consequat lacus non eros feugiat fermentum.
                                </p>
                                <h5>Authorship</h5>
                                An author is considered to have made substantive intellectual contribution 
                                to a published study, as defined by having made substantial contributions 
                                to conception and design, or acquisition of data, or analysis and 
                                interpretation of data; having been involved in drafting the manuscript 
                                or revising it critically for important intellectual content; having given 
                                final approval of the version to be published. Each author should have 
                                participated sufficiently in the work to take public responsibility for 
                                appropriate portions of the content; having agreed to be accountable for 
                                all aspects of the work in ensuring that questions related to the accuracy 
                                or integrity of any part of the work are appropriately investigated and 
                                resolved. Acquisition of funding, collection of data, or general supervision 
                                of the research group alone does not usually justify authorship. 
                                All contributors who do not meet the criteria for authorship should be listed 
                                in Acknowledgements. The involvement of scientific writers should be 
                                acknowledged, along with their source of funding. Longdom Publishing 
                                requires written confirmation from all authors that they agree with any 
                                proposed changes in authorship of already submitted manuscripts. */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Editorialpolicies