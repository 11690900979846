import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../../footer";
import {
  User1,
  Html,
  Pdf
} from "../../imagepath";
import { Col, Row } from "react-bootstrap";
import CourseHeader from "../../header/index";

import StudentSideBar from "../../student/sidebar";
import Bannerpage from "../../Banner";
import { useDispatch, useSelector } from "react-redux";
import { ArticlegetById } from "../../../Redux/Articles/articleSlice";
import moment from "moment";
// import queryString from "query-string";

export default function InstructorReviews() {

  const { article } = useSelector((state) => state.articleData)

  const dispatch = useDispatch();
  const { slug } = useParams();

  useEffect(() => {
    dispatch(ArticlegetById(slug));
  }, [])

  return (
    <>
      <CourseHeader activeMenu={"CourseDetails"} />
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="breadcrumb-list">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Courses
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      All Courses
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      The Complete Web Developer Course 2.0
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Bannerpage />

      <div className="container p-2">
        <Row>
          <StudentSideBar />
          <Col>
            <div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="comman-space">
                          <h3>Articles in press and Articles in process</h3>
                        </div>
                        {article?.data?.map((item, index) => {
                          return <div key={index} style={{ padding: 10 }}>
                            <Row >
                              <Col>
                                {String(item?.article_types?.[0]?.name).charAt(0).toUpperCase() + String(item?.article_types?.[0]?.name).slice(1)}
                              </Col>
                              <Col>
                                <div style={{ textAlign: 'right' }}>
                                  Pages: {item?.start_page_number} - {item?.end_page_number}
                                </div>
                              </Col>
                            </Row>
                            <div className="sell-course-head">
                            </div>
                            <div className="comman-space" >
                              <div className="instruct-review-blk ">
                                <div className="review-item">
                                  <div className="instructor-wrap border-0 m-0">
                                    <div className="about-instructor">
                                      <div className="abt-instructor-img">
                                        <Link
                                        >
                                          <img
                                            src={User1}
                                            alt="img"
                                            className="img-fluid"
                                          />
                                        </Link>
                                      </div>
                                      <div className="instructor-detail">
                                        <h5>
                                          <Link
                                            to={item?.pdf_url}
                                          // target="_blank"
                                          >
                                            {String(item?.article_title).charAt(0).toUpperCase() + String(item?.article_title).slice(1)}
                                          </Link>
                                        </h5>
                                        <p>
                                          <Link
                                            to={`/${slug}/author/${item?.slug}/article`}
                                            state={"article"}
                                          // target="_blank"
                                          >
                                            <em>{item?.authors?.[0]?.author_name}</em>
                                          </Link>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="rating">
                                      <i className="fa-brands fa-facebook"></i>
                                      <i className="fa-brands fa-twitter"></i>
                                      <i className="fa-brands fa-linkedin"></i>
                                      <i className="fa-brands fa-instagram"></i>
                                    </div>
                                  </div>
                                  <p className="rev-info">
                                    Published Date: {moment(item?.published_date).format("YYYY-MM-DD")}; Received Date: {moment(item?.received_date).format("YYYY-MM-DD")}
                                    {/* <div dangerouslySetInnerHTML={{ __html: item?.abstract }}></div> */}
                                  </p>
                                  <div className="about-instructor">
                                    <div>
                                      <Link
                                        to={`/${slug}/article/${item?.slug}`}
                                        state={"article"}
                                      // target="_blank"
                                      >
                                        <img src={Html} alt="html" width="30" />
                                      </Link>
                                    </div>
                                    <div >
                                      <Link
                                        to={item?.pdf_url}
                                      // target="_blank"
                                      >
                                        <img
                                          src={Pdf}
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}
