import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

// const [data, setData] = useState('')
const initialState = {
    isLogin: false,
    journalList: {},
    browseBy: {},
    atoz: {},
    toastData: {},
    browseBySubject: {}
};

const journalSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setJournalList(state, action) {
            state.journalList = action.payload;
        },
        setBrowseBy(state, action) {
            state.browseBy = action.payload
        },
        setBrowseBySubject(state, action) {
            state.browseBySubject = action.payload
        },
        setAtoZ(state, action) {
            state.atoz = action.payload
        },
        settoastData(state, action) {
            state.toastData = action.payload;
        },
    },
});

export const { setJournalList, settoastData, setBrowseBy, setAtoZ, setBrowseBySubject } = journalSlice.actions;

export const JournalList = (name) => async (dispatch) => {
    // 
    const response = await axios.get(`${process.env.REACT_APP_URL}/page?page_name=${name}`).then((res) => {
        // dispatch(settoastData({ status: true, message: "You are Loged in !" }));
        dispatch(setJournalList(res.data));
        // localStorage.setItem("user", JSON.stringify(res.data))
    })
        .catch((err) => {
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
    return response;
}

export const JournalListPageDynamic = (name) => async (dispatch) => {
    // 
    // const response = await axios.get(`${process.env.REACT_APP_URL}/page?page_name=${name}`).then((res) => {
    const response = await axios.get(`${process.env.REACT_APP_URL}/pageDetails?slug=${name}`).then((res) => {
        // dispatch(settoastData({ status: true, message: "You are Loged in !" }));
        dispatch(setJournalList(res.data));
        // localStorage.setItem("user", JSON.stringify(res.data))
    })
        .catch((err) => {
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
    return response;
}

export const JournalBrowsebyList = (search) => async (dispatch) => {

    const response = await axios.get(`${process.env.REACT_APP_URL}/journal/browseBySubject?search=${search}`).then((res) => {
        // dispatch(settoastData({ status: true, message: "You are Loged in !" }));
        dispatch(setBrowseBy(res.data));
        // localStorage.setItem("user", JSON.stringify(res.data))
    })
        .catch((err) => {
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
    return response;
}

export const JournalBrowsebySubject = (name) => async (dispatch) => {

    const response = await axios.get(`${process.env.REACT_APP_URL}/journal/browseBySubjectDetails/${name}`).then((res) => {
        // dispatch(settoastData({ status: true, message: "You are Loged in !" }));
        dispatch(setBrowseBySubject(res.data));
        // localStorage.setItem("user", JSON.stringify(res.data))
    })
        .catch((err) => {
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
    return response;
}


export const JournalAtoZList = (search) => async (dispatch) => {
    const response = await axios.get(`${process.env.REACT_APP_URL}/journal/AtoZ?search=${search}`).then((res) => {
        // dispatch(settoastData({ status: true, message: "You are Loged in !" }));
        dispatch(setAtoZ(res.data));
        // localStorage.setItem("user", JSON.stringify(res.data))
    })
        .catch((err) => {
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
    return response;
}

const journalReducer = journalSlice.reducer;

export default journalReducer;
