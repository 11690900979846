import React, { useState } from "react";
import Header from "../header";
// import Loginbg from "../../assets/img/journals-image.jpeg";
import TrendingCourse from "./slider/trendingCourse";
import Footer from "../footer";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import about from '../../assets/img/Journal -About.jpeg'
import { HomeDataget, HomeJournalDataget, getCurrentHighlights } from "../../Redux/home/homeslice";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import Bannerpage from "../Banner";

export const Home = () => {

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  const dispatch = useDispatch();
  const navigation = useNavigate();

  const NavigateFun = (dataID, jounalsD) => {
    navigation(`/${dataID?.journal_slug}/journal-page`, {
      state: { journals: jounalsD } // 'state' is used to pass the data
    });
  }

  const [page, setPage] = useState(1);
  const limit = 10

  useEffect(() => {
    dispatch(HomeDataget());
    dispatch(getCurrentHighlights(''));
    dispatch(HomeJournalDataget(page - 1, limit))
  }, [page])

  const { homeData, homeJournalData } = useSelector((state) => state.Home)

  return (
    <>
      <div className="main-wrapper">
        <Header />
        {/* <div style={{
          marginTop: "0%",
          padding: "5%",
          backgroundImage: "url(" + Loginbg + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "80vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <div className="container whitetext" >
            <div className="section-sub-head pt-2">
              <h2 style={{ color: "#fff", textAlign: "left", paddingTop: "20px" }}>Journal’s Feed</h2>
            </div>
            <div className="section-text aos" data-aos="fade-up">
              <p className="mb-0" style={{ color: "#fff", textAlign: "left", paddingTop: "20px" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget
                aenean accumsan bibendum gravida maecenas augue elementum et
                neque. Suspendisse imperdiet.
              </p>
            </div>
          </div>
        </div> */}
        <div style={{ marginTop: '5%' }}>
          <Bannerpage />
        </div>
        <section className="section how-it-works">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-md-6 col-sm-12 ">
                <div className="section-header aos" data-aos="fade-up">
                  <div className="section-sub-head">
                    <span>About Us </span>
                    <h2>Welcome To Journal’s Feed</h2>
                  </div>
                </div>
                <div className="section-text aos" data-aos="fade-up">
                  <div dangerouslySetInnerHTML={{ __html: homeData?.data?.about_text }}></div>
                </div>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <img src={about} alt="About Image" style={{ height: "300px", width: "100%" }} />
              </div>
            </div>
          </div>
        </section>

        <section className="section ">
          <div className="container">
            <div className="section-header aos" data-aos="fade-up">
              <div className="section-sub-head">
                <span>What’s New</span>
                <h2>Scientific Journals</h2>
              </div>
            </div>
            <div className="section-text aos" data-aos="fade-up">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget
                aenean accumsan bibendum gravida maecenas augue elementum et
                neque. Suspendisse imperdiet.
              </p>
            </div>
            <div className="course-feature">
              <div className="row">
                {homeJournalData?.data?.data.map(detail => (
                  <div key={detail.uuid} className="col-lg-3 col-md-6 d-flex">
                    <div className="course-box d-flex aos" data-aos="fade-up">
                      <div className="product">
                        <div className="product-img">
                          <img className="img-fluid" alt="" crossOrigin="anonymous" src={detail?.image_url} />
                        </div>
                        <div className="product-content">
                          <div className="course-group d-flex">
                            <div className="course-group-img d-flex">
                              <div className="course-name">
                                <h4>
                                  {detail.name}
                                </h4>
                                {/* <p>Author</p> */}
                              </div>
                            </div>
                          </div>
                          <div className="course-items">
                            <ul>
                              {detail.journals.map((item, index) => (
                                <>
                                  {index <= 2 && <li
                                    key={index}
                                    onClick={() => NavigateFun(item, detail?.journals)}
                                  >
                                    <Link>
                                      {item?.journal_name}
                                    </Link>
                                  </li>
                                  }
                                </>
                              ))}
                            </ul>
                          </div>
                          <div >
                            <hr />
                            <div className="all-btn all-category d-flex align-items-center" >
                              <Link to={`/${detail?.slug}/more-calssifications`} className="btn btn-primary">
                                VIEW MORE
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <PaginationControl
                page={page}
                between={4}
                total={Number(homeJournalData?.data?.count)}
                limit={limit}
                changePage={(page) => {
                  setPage(page)
                }}
                ellipsis={1}
              />
            </div>
          </div>
        </section >
        <section>
          <TrendingCourse
          />
        </section>
        <Footer />
      </div >
    </>
  );
};

export default Home;
