import React, { useEffect } from 'react';
// import { Provider } from 'react-redux';
// import { store
// } from './store';
import Approuter from './approuter';
import { settingsData } from './Redux/home/homeslice';
import { useDispatch } from 'react-redux';


const App = () => {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(settingsData())
  }, [])

  return (
      <Approuter />

    // <Provider store={store}>
    //   <PersistGate loading={<div>Loading...</div>} persistor={persistedStore}>
    //     <Approuter />
    //   </PersistGate>
    // </Provider>
  );
};

export default App;
