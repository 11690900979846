import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
// import InstructorSidebar from "../instructor/sidebar/index";
import '../Journal/journal.css'
import Footer from "../footer";
import html from '../../assets/img/html.svg'
import pdf from "../../assets/img/PDF_32.png"
import {
    NetIcon01,
    NetIcon02,
    NetIcon03,
    NetIcon04
} from '../imagepath'
import { useDispatch, useSelector } from "react-redux";
import { ArticlegetByArticleId } from "../../Redux/Articles/articleSlice";
import CourseHeader from "../header/index";
import Bannerpage from "../Banner";
import StudentSideBar from "../student/sidebar";
// import queryString from 'query-string';

const volumePage = () => {
    const { id, name, slug } = useParams();

    const { articleByarticleId } = useSelector((state) => state.articleData)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ArticlegetByArticleId(window.atob(id)));
    }, [])

    // const articles = [
    //     {
    //         title: "Advances in Bone Marrow Research: A New Therapeutic Potential",
    //         author: "Chris Beagan",
    //         doi: "10.35841/2572-4916.24.12.271",
    //         pages: "1 - 2",
    //         type: "Short Communication"
    //     },
    //     {
    //         title: "Advances in the Management and Treatment of Ankle Fractures",
    //         author: "Jian Hong",
    //         doi: "10.35841/2572-4916.24.12.272",
    //         pages: "1 - 2",
    //         type: "Short Communication"
    //     },
    //     {
    //         title: "Hip Fracture: A Important Health Issue Requiring Comprehensive Care and Prevention",
    //         author: "Nohara Takamoshi",
    //         doi: "10.35841/2572-4916.24.12.273",
    //         pages: "1 - 2",
    //         type: "Commentary"
    //     },
    //     {
    //         title: "Knee Osteoporosis: Important Measures for Comprehensive Care and Prevention",
    //         author: "Laura Jhonson",
    //         doi: "10.35841/2572-4916.24.12.274",
    //         pages: "1 - 2",
    //         type: "Commentary"
    //     },
    //     {
    //         title: "The Evolution of the Elbow: A Perspective on Its Anatomy, Injuries, and Treatment",
    //         author: "Yoko Mishima",
    //         doi: "10.35841/2572-4916.24.12.275",
    //         pages: "1 - 2",
    //         type: "Perspective"
    //     },
    //     {
    //         title: "Skull Fractures: Exploring Types, Treatments, and Implications",
    //         author: "Xiong Min",
    //         doi: "10.35841/2572-4916.24.12.276",
    //         pages: "1 - 2",
    //         type: "Perspective"
    //     },
    //     {
    //         title: "Arm Fracture: Impact and Recovery",
    //         author: "Nicole Watson",
    //         doi: "10.35841/2572-4916.24.12.277",
    //         pages: "1 - 2",
    //         type: "Opinion Article"
    //     },
    //     {
    //         title: "Bone Cancer: Medical Advancement Through Treatment Innovations",
    //         author: "Shanmuk Ganeshan",
    //         doi: "10.35841/2572-4916.24.12.278",
    //         pages: "1 - 2",
    //         type: "Opinion"
    //     },
    //     {
    //         title: "Bone Grafting: Advancing Science and Applications",
    //         author: "Jian Wang",
    //         doi: "10.35841/2572-4916.24.12.279",
    //         pages: "1 - 2",
    //         type: "Commentary Article"
    //     },
    //     {
    //         title: "The Role of Calcium Supplements in Bone Health: A Perspective",
    //         author: "Yun Fang",
    //         doi: "10.35841/2572-4916.24.12.280",
    //         pages: "1 - 2",
    //         type: "Perspective Article"
    //     }
    // ];

    // const getNameById = (data, uuid) => {
    //     let name = ''
    //     data?.map((item) => {
    //         if (item?.uuid === uuid) {
    //             name = item?.name
    //         }
    //         return name;
    //     })
    // }

    return (
        <>
            <div className="main-wrapper">
                <CourseHeader activeMenu={"CourseDetails"} />
                <div className="breadcrumb-bar">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <div className="breadcrumb-list">
                                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li className="breadcrumb-item" aria-current="page">
                                                Courses
                                            </li>
                                            <li className="breadcrumb-item" aria-current="page">
                                                All Courses
                                            </li>
                                            <li className="breadcrumb-item" aria-current="page">
                                                The Complete Web Developer Course 2.0
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Bannerpage />
                <div className="container p-2">
                    <Row>
                        <StudentSideBar activeMenu="Courses" />
                        <Col style={{ backgroundColor: "#fff" }}>

                            <div className="row p-3">
                                {/* Instructor List */}
                                <div>
                                    <h1>{window.atob(name)}</h1>
                                    {articleByarticleId?.data?.map((article, index) => (
                                        <div key={index} style={{ padding: '10px 0' }}>
                                            <Row >
                                                <Col>
                                                    {String(article?.article_types?.[0]?.name).charAt(0).toUpperCase() + String(article?.article_types?.[0]?.name).slice(1)}
                                                </Col>
                                                <Col>
                                                    <div style={{ textAlign: 'right' }}>
                                                        Pages: {article?.start_page_number} - {article?.end_page_number}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="sell-course-head">
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div>
                                                    {/* <p ><strong>{getNameById(article.article_types, article.article_type_uuid)}</strong></p> */}
                                                    <Link
                                                        to={`/${slug}/archive/${window.atob(name)}/${article?.slug}`}
                                                        state={"article"}
                                                    >
                                                        <h5 style={{ textAlign: "left" }}>{article.article_title}</h5>
                                                    </Link>
                                                    <p>
                                                        <Link
                                                            to={`/${slug}/author/${article?.slug}/archive`}
                                                            state={"article"}
                                                        // target="_blank"
                                                        >
                                                            <em>{article?.authors?.[0]?.author_name}</em>
                                                        </Link>
                                                    </p>
                                                    <p>DOI:                                                     <Link
                                                        to={`/${slug}/archive/${window.atob(name)}/${article?.slug}`}
                                                        state={"article"}
                                                    >
                                                        {article.doi}
                                                    </Link></p>
                                                </div>
                                                {/* <div style={{ textAlign: 'right' }}>
                                                <p>Pages: {article.pages}</p>
                                            </div> */}
                                            </div>
                                            <div className="row">
                                                <div className="col">

                                                    <Link
                                                        to={`/${slug}/archive/${window.atob(name)}/${article?.slug}`}
                                                        state={"article"}
                                                    // target="_blank"
                                                    >
                                                        <img src={html} alt="html" width="30" />
                                                    </Link>
                                                    <Link
                                                        to={article?.pdf_url}
                                                    // target="_blank"
                                                    >
                                                        <img src={pdf} alt="pdf" />
                                                    </Link>
                                                </div>
                                                <div className="col" style={{ textAlign: "right" }}>
                                                    Share this article
                                                    <img src={NetIcon01} alt="icon1" />
                                                    <img src={NetIcon02} alt="icon1" />
                                                    <img src={NetIcon03} alt="icon1" />
                                                    <img src={NetIcon04} alt="icon1" />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>

                    </Row>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default volumePage