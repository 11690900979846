// import React from "react";
// import ReactDOM from "react-dom/client";

// // import { App } from "./app";
// // import 'bootstrap/dist/css/bootstrap.min.css';
// // import "./assets/css/bootstrap.min.css";

// //Bootstrap
// import "bootstrap/dist/css/bootstrap.min.css";

// //Font Awesome
// import "./assets/plugins/fontawesome/css/fontawesome.min.css";
// import "./assets/plugins/fontawesome/css/all.min.css";

// //CSS
// import "./assets/css/style.css";

// // import Approuter from "./approuter";


// // Redux Intiate
// import { Provider } from 'react-redux';
// import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
// import { store, persistedStore } from './store.js';

// const root = ReactDOM.createRoot(document.getElementById("root"));
// const Approuter = React.lazy(() => import('./approuter'));
// root.render(
//   <Provider store={store}>
//     <PersistGate loading={<div><center>Loading...</center></div>} persistor={persistedStore}>
//       <React.Suspense fallback={<div><center>Loading...</center></div>}>
//         <Approuter />
//       </React.Suspense>
//     </PersistGate>
//   </Provider>
// );

// // root.render(
// //   <Provider store={store}>
// //     <PersistGate loading={null} persistor={persistedStore}>
// //       <Approuter />
// //     </PersistGate>
// //   </Provider>
// // );

// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';

// Optional: import global styles like bootstrap or font-awesome
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/plugins/fontawesome/css/fontawesome.min.css';
import './assets/plugins/fontawesome/css/all.min.css';
import './assets/css/style.css';

import { Provider } from 'react-redux';
import { store } from './store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer />
        <App />
    </Provider>
  </React.StrictMode>
);

