// import React, { useEffect } from 'react'
// import Footer from '../footer'
// import Header from '../header'
// import { useDispatch, useSelector } from 'react-redux';
// import { settingsData } from '../../Redux/home/homeslice';

// const ContactUs = () => {

//     const dispatch = useDispatch();
//     useEffect(() => {
//       dispatch(settingsData())
//     },[])

//     const { settings } = useSelector((state) => state.Home);

//     return (
//         <>
//             <Header />
//             <div className="breadcrumb-bar">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-md-12 col-12">
//                             <div className="breadcrumb-list">
//                                 <nav aria-label="breadcrumb" className="page-breadcrumb">
//                                 </nav>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div className="page-banner">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-md-12 col-12">
//                             <h1 className="mb-0">Contact Us</h1>
//                         </div>
//                     </div>
//                 </div>
//             </div>      
//             <div className='topspace'>
//                 <div className='container'>
//                     <div className='row'>
//                         <div className='col-4'>
//                             <h4>ADDRESS</h4>
//                             <div dangerouslySetInnerHTML={{__html : settings?.data?.address}}/>
//                             {/* <p>1-1, Unknown Area, Not found City <br />
//                                 Somewhere in earth</p> */}
//                         </div>
//                         <div className='col-4'>
//                             <h4>Contact Number</h4>
//                             <p>{settings?.data?.phone}</p>
//                         </div>
//                         <div className='col-4'>
//                             <h4>Contact Email</h4>
//                             <p>{settings?.data?.email}</p>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <Footer />
//         </>
//     )
// }

// export default ContactUs

import React, { useEffect, useState } from "react";
// import CourseHeader from "../header";
import '../Journal/journal.css'
import Footer from "../footer";
// import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { settingsData } from "../../Redux/home/homeslice";
import Header from "../header";
import { toast } from 'react-toastify';
import { useFormik } from "formik";
import * as Yup from "yup";

const ContactUs = () => {

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        message: ""
    });


    const handleSubmitAPI = (values, fun) => {
        // e.preventDefault()
        axios.post(`${process.env.REACT_APP_URL}/home/contactQuery`, values)
            .then((res) => {
                console.log(res?.data)
                setFormData({
                    first_name: "",
                    last_name: "",
                    email: "",
                    mobile: "",
                    message: ""
                })
                toast.success("Detailes Saved !")
                fun();

            })
            .catch((err) => {
                console.log(err?.response?.data)
                toast.error(err?.response?.data?.message ? err?.response?.data?.message : err?.response?.data)
            })
        console.log(formData);
    }

    // const handleChange = (event) => {
    //     const { name, value } = event.target;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [name]: value
    //     }));
    // };

    
    const formik = useFormik({
        initialValues: {
          first_name: "",
          last_name: "",
          email: "",
          mobile: "",
          message: "",
        },
        validationSchema: Yup.object({
          first_name: Yup.string()
            .min(2, "First name must be at least 2 characters")
            .max(50, "First name cannot exceed 50 characters")
            .required("First name is required"),
          last_name: Yup.string()
            .min(2, "Last name must be at least 2 characters")
            .max(50, "Last name cannot exceed 50 characters")
            .required("Last name is required"),
          email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
          mobile: Yup.string()
            .matches(/^\d{10}$/, "Phone number must be 10 digits")
            .required("Phone number is required"),
          message: Yup.string()
            .min(10, "Message must be at least 10 characters")
            .required("Message is required"),
        }),
        onSubmit: (values, { resetForm }) => {
          console.log("Form data", values);
        handleSubmitAPI(values, resetForm);
          // Add your API call logic here
        },
      });

    const dispatch = useDispatch();
    // const { slug } = useParams();

    useEffect(() => {
        dispatch(settingsData());
    }, [])

    const { settings } = useSelector((state) => state.Home);

    return (
        <>
            <Header />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h1 className="mb-0">Contact Us</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 mx-auto">
                            <div className="support-wrap">
                                <div className="row">
                                    <div className="col-md-5 col-lg-5 col-xl-5 col-sm-6">
                                        <strong>Address</strong>
                                        <br />
                                        <label dangerouslySetInnerHTML={{ __html: settings?.data?.address }}>
                                        </label>
                                        <br />
                                        <strong>Registered Address</strong>
                                        <br />
                                        <label
                                            dangerouslySetInnerHTML={{ __html: settings?.data?.registered_address }}
                                        >
                                        </label>
                                        <br />
                                        <strong>Phone Number</strong>
                                        <br />
                                        <label>
                                            {settings?.data?.phone}
                                        </label>
                                        <br />
                                        <strong>Email</strong>
                                        <br />
                                        <label>
                                            {settings?.data?.email}
                                        </label>
                                        <br />
                                        <strong>Reprints / Advertisement</strong>
                                        <br />
                                        <label>
                                            {settings?.data?.reprint_advertisement}
                                        </label>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-xl-6 col-sm-6">
                                        {/* <form onSubmit={handleSubmitAPI} >
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>First Name</label>
                                                        <input
                                                            name="first_name"
                                                            value={formData.first_name}
                                                            onChange={handleChange}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter your first name"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Last Name</label>
                                                        <input
                                                            name="last_name"
                                                            value={formData.last_name}
                                                            onChange={handleChange}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter your last name"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Email</label>
                                                        <input
                                                            name="email"
                                                            value={formData.email}
                                                            onChange={handleChange}
                                                            type="email"
                                                            className="form-control"
                                                            placeholder="Enter your email address"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Phone</label>
                                                        <input
                                                            name="mobile"
                                                            value={formData.mobile}
                                                            onChange={handleChange}
                                                            type="tel"
                                                            className="form-control"
                                                            placeholder="Enter your phone number"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Message</label>
                                                        <textarea
                                                            name="message"
                                                            value={formData.message}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Write down here"
                                                            rows={4}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <button className="btn-submit">Submit</button>
                                                </div>
                                            </div>
                                        </form> */}
 <form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>First Name</label>
            <input
              name="first_name"
              type="text"
              className={formik.touched.first_name && formik.errors.first_name ? "form-control is-invalid" : "form-control"}
              placeholder="Enter your first name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {/* {formik.touched.first_name && formik.errors.first_name ? (
              <div className="error">{formik.errors.first_name}</div>
            ) : null} */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>Last Name</label>
            <input
              name="last_name"
              type="text"
              className={formik.touched.last_name && formik.errors.last_name ? "form-control is-invalid" : "form-control"}
              placeholder="Enter your last name"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {/* {formik.touched.last_name && formik.errors.last_name ? (
              <div className="error">{formik.errors.last_name}</div>
            ) : null} */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>Email</label>
            <input
              name="email"
              type="email"
              className={formik.touched.email && formik.errors.email ? "form-control is-invalid" : "form-control"}
              placeholder="Enter your email address"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {/* {formik.touched.email && formik.errors.email ? (
              <div className="error">{formik.errors.email}</div>
            ) : null} */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>Phone</label>
            <input
              name="mobile"
              type="tel"
              className={formik.touched.mobile && formik.errors.mobile ? "form-control is-invalid" : "form-control"}
              placeholder="Enter your phone number"
              value={formik.values.mobile}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.mobile && formik.errors.mobile ? (
              <div className="error">{formik.errors.mobile}</div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>Message</label>
            <textarea
              name="message"
              className={formik.touched.message && formik.errors.message ? "form-control is-invalid" : "form-control"}
              placeholder="Write down here"
              rows={4}
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {/* {formik.touched.message && formik.errors.message ? (
              <div className="error">{formik.errors.message}</div>
            ) : null} */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <button type="submit" className="btn-submit">
            Submit
          </button>
        </div>
      </div>
    </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ContactUs